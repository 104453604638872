import * as React from "react"
import { Page, Card, Button, Form, Grid } from "tabler-react"
import { observer } from "mobx-react"
import If from "../../components/if"

@observer
class PageForm extends React.Component {

    render() {

        const selectDecreto = (
            <Form.Select
                label="Decreto"
                onChange={(e) => this.props.store.onChangeDecreto(e.target.value)}
                value={this.props.store.decreto_id}
                disabled={this.props.store.id}
                feedback={this.props.store.wasValidated ? this.props.store.decreto_idError : ''}
                invalid={this.props.store.wasValidated && this.props.store.decreto_idError}
            >
                <option value="">Selecione</option>
                {this.props.store.decretoSelect.map(item => {
                    return (<option key={item.id} value={item.id}>{item.descricao}</option>)
                })}
            </Form.Select>
        )

        const selectOrigem = (
            <Form.Select
                label="Origem"
                onChange={(e) => this.props.store.onChangeOrigem(e.target.value)}
                value={this.props.store.origem_id}
                disabled={!this.props.store.exibe_campo_origem}

                feedback={this.props.store.wasValidated ? this.props.store.origem_idError : ''}
                invalid={this.props.store.wasValidated && this.props.store.origem_idError}
            >
                <option value="">Selecione</option>
                {this.props.store.origemSelect.map(item => {
                    if (this.props.store.decreto_id !== item.decreto_id) {
                        return null
                    }
                    return (<option key={item.id} value={item.id}>{item.nome}</option>)
                })}
            </Form.Select>
        )

        return (
            <>
                <Page.Content>
                    <Page.Header>
                        <Page.Title>
                            Remessa
                        </Page.Title>
                    </Page.Header>
                </Page.Content>

                <Page.Card
                    footer={
                        <Card.Footer>
                            <div className="d-flex">
                                <Button link className="mr-auto" onClick={e => this.props.onBack()} type="button">Voltar</Button>
                                <If test={this.props.store.id && !['C', 'P'].includes(this.props.store.status)}>
                                    <Button
                                        color="success"
                                        className="mr-1"
                                        type="button"
                                        onClick={() => this.props.calcular(this.props.store.id, this.props.store.status === 'C')}
                                    >
                                        Calcular remessa
                                    </Button>
                                </If>
                                <If test={!['C', 'P'].includes(this.props.store.status)}>
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={e => this.props.onSave()}
                                    >
                                        {this.props.store.id ? 'Atualizar' : 'Salvar'}
                                    </Button>
                                </If>
                            </div>
                        </Card.Footer>
                    }
                >

                    <Grid.Row>
                        <Grid.Col md={3}>
                            <Form.Checkbox
                                label="Remessa retificadora"
                                checked={this.props.store.retificado}
                                onChange={(e) => this.props.store.onChangeRetificado(e.target.checked)}
                                disabled={this.props.store.id}
                            />
                        </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>

                        <Grid.Col md={2}>
                            <Form.Input
                                label='Competência'
                                type='text'
                                value={this.props.store.getCompetencia}
                                onChange={(e) => this.props.store.onChangeCompetencia(e.target.value)}
                                maxLength="7"
                                disabled={this.props.store.id}
                                placeholder='AAAA/MM'

                                feedback={this.props.store.wasValidated ? this.props.store.competenciaError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.competenciaError}
                            />
                        </Grid.Col>

                        <Grid.Col md={2}>
                            {selectDecreto}
                        </Grid.Col>

                        <Grid.Col md={4}>
                            {selectOrigem}
                        </Grid.Col>

                        <Grid.Col md={2}>
                            <Form.Input
                                type='text'
                                label="Valor repasse"
                                value={this.props.store.valorRepasse}
                                onChange={(e) => this.props.store.onChangeValorRepasse(e.target.value)}
                                disabled={this.props.store.desabilita_campo_valor_repasse}
                                placeholder="Valor repasse"

                                feedback={this.props.store.wasValidated ? this.props.store.valor_repasseError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.valor_repasseError}
                            />
                        </Grid.Col>

                        <Grid.Col md={2}>
                            <Form.Input
                                type='text'
                                label="Valor FPO"
                                value={this.props.store.valorFPO}
                                disabled={this.props.store.exibe_campo_fpo ? false : true}
                                onChange={(e) => this.props.store.onChangeValorFPO(e.target.value)}
                                placeholder="Valor FPO"

                                feedback={this.props.store.wasValidated ? this.props.store.valor_fpoError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.valor_fpoError}
                            />
                        </Grid.Col>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col md={2}>
                            <Form.Input
                                type='text'
                                label="Qtde. dias úteis"
                                value={this.props.store.qtdeDiasUteis}
                                disabled={this.props.store.desabilita_campo_qtde_dias_uteis}
                                onChange={(e) => this.props.store.setQtdeDiasUteis(e.target.value)}
                                placeholder="Qtde. dias úteis"
                                maxLength="2"

                                feedback={this.props.store.wasValidated ? this.props.store.qtde_dias_uteisError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.qtde_dias_uteisError}
                            />
                        </Grid.Col>
                    </Grid.Row>


                </Page.Card>
            </>
        )
    }
}

export default PageForm